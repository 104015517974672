$theme-purple: #7F00BB;
$theme-white: #FFFFFF;
$theme-grey: #555555;
$theme-dark-blue: #160D3A;
$theme-black: #000000;
$theme-black-two: #333333;
$theme-brown: #CC7A64;
$theme-pink: #B756A9;
$theme-text-grey: #999999;
$theme-off-white: #f8f9fa;
$theme-line-divider:rgba(0,0,0,0.2);



@mixin text-gradient($direction) {
  background: linear-gradient(to $direction, $theme-brown , $theme-pink);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}