.event-status {
  .top {
    margin-top: 80px;
    .text {
      display: flex;
      align-items: center;

      h2 {
        font-size: 30px;
        font-weight: 600;
        color: rgba(127, 0, 187, 0.6);
      }
      h1 {
        font-size: 30px;
        font-weight: 600;
        color: $theme-purple;
      }
    }
    .search-button {
      display: flex;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      gap: 20px;
      .search {
        width: 600px;
        border-radius: 8px;
        padding: 3px 10px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        font-size: 14px;
      }
      .select {
        width: 150px;
      }
      .button {
        outline: none;
        border: none;
        background: $theme-purple;
        color: $theme-white;
        padding: 11px 35px;
        font-weight: 500;
        font-size: 16px;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  .bluck {
    width: fit-content;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 11;
    margin-left: 150px;

    // justify-content: right;
    gap: 15px;

    margin-top: 30px;
    margin-bottom: -50px;
    .selected{
      font-size: 12px ;
      color: $theme-grey;
      font-weight: 500;
    }
    p {
      font-size: 16px;
      color: #000000;
      font-weight: 500;
      width: max-content;
    }
  }

  .table {
    margin-top: 0px;

    .MuiBox-root {
      .MuiDataGrid-root {
        border: none;

        div {
          div {
            .MuiDataGrid-toolbarContainer {
              padding-bottom: 10px;
              margin-bottom: 20px;
              padding-right: 20px;
              padding-top: 10px;
              border-bottom: 2px solid rgba(127, 0, 187, 0.6);

              button {
                border: 1px solid rgba(0, 0, 0, 0.2);
                padding: 8px 20px;
                margin: left;
                position: relative;
                z-index: 1;
                font-weight: 500;
              }
            }
          }
        }
        .MuiDataGrid-main {
          border: 1px solid rgba(0, 0, 0, 0.2);
        }
        div {
          .MuiDataGrid-footerContainer {
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-top: none;
          }
        }
      }
    }
  }
}
