.add-group {
  width: 90%;
  margin: 50px auto;

  .body {
    padding: 20px 40px;
    margin-top: 20px;
    border-radius: 12px;

    .row {
      display: flex;
      align-items: flex-start;
      column-gap: 50px;

      .generate-btn {
        margin-top: 33px;
      }
    }

    .field {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
      width: 47%;
      label {
        color: $theme-grey;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 2px;
      }

      .chips {
        margin-top: 4px;
        .chip {
          border-radius: 5px;
          font-weight: 500;
          .MuiChip-label {
            padding-left: 8px;
          }
        }
      }
    }
  }

  .submit {
    text-align: right;
    margin-top: 20px;
  }
}

.groups {
  width: 95%;
  margin: 50px auto;

  .list {
    margin: 10px 0 20px 0;
    .card-list {
      display: flex;
      column-gap: 20px;
    }
  }

  .details {
    display: flex;
    padding: 20px;
    gap: 10px;

    .left {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .name,
      .labels {
        padding: 20px 15px;
        min-width: 250px;
        border-radius: 16px;
        max-width: 280px;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        word-wrap: break-word;

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        h3 {
          font-weight: 600;
          font-size: 22px;
        }
        p {
          font-size: 15px;
        }

        .chip {
          border-radius: 8px;
          font-weight: 500;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 62%;

      .desc {
        padding: 20px 15px;
        min-width: 250px;
        border-radius: 16px;
      }

      .members {
        padding: 20px 15px;
        border-radius: 16px;

        .mem {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
        .role {
          text-transform: capitalize;
          font-weight: 500;
        }
      }

      .info {
        display: flex;
        gap: 10px;

        .group-info {
          display: flex;
          gap: 10px;

          .data {
            padding: 20px 15px;
            border-radius: 16px;
            text-align: center;
            min-width: 110px;

            p {
              font-weight: 500;
              font-size: 15px;
            }
            h2 {
              color: $theme-purple;
            }
          }
        }

        .active-job {
          padding: 20px 15px;
          border-radius: 16px;
          width: 100%;
          h3 {
            font-weight: 600;
          }
        }
      }
    }
  }
}

/* MISC */
.group-card {
  background: $theme-off-white;
  border-radius: 12px;
  padding: 20px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    overflow-x: scroll;

    .text {
      cursor: pointer;
      h3 {
        font-size: 16px;
        font-weight: 700;
      }
      p {
        font-size: 14px;
        font-weight: 500;
        color: $theme-grey;
      }
    }

    .btns {
      button {
        text-transform: capitalize;
        border-radius: 12px;
      }
    }
  }
}
