.dashboard {
  padding-right: 30px;
  .top {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 20px;

    .title {
      font-size: 36px;
      font-weight: 700;
      color: #1e1e1e;
    }
    .button-group {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      .button {
        text-transform: capitalize;
        color: #555555;
        font-weight: 400;
        border: 0px;
        border-radius: 8px;
        padding: 8px 30px;
      }
      .active {
        background: $theme-purple;
        color: $theme-white;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      .button:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .button:nth-child(2) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }
  .header-section {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    .select-time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .button-group {
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        .button {
          text-transform: capitalize;
          color: #555555;
          font-weight: 400;
          border: 0px;
          border-radius: 8px;
          padding: 8px 20px;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        .active {
          background: $theme-purple;
          color: $theme-white;
        }
        .active-first {
          background: $theme-purple;
          color: $theme-white;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .active-last {
          background: $theme-purple;
          color: $theme-white;
          border-radius: 0px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        .button:last-child {
          border-right: 0px;
        }
      }
    }
    .card-body {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
      overflow-x: scroll;

      .card {
        min-height: 120px;
        min-width: 222px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        padding: 10px 20px;
        h4 {
          font-size: 14px;
          font-weight: 600;
          color: #212121;
        }
        h1 {
          font-size: 40px;
          font-weight: 700;
          color: #feaa00;
        }
        p {
          font-size: 14px;
          font-weight: 500;
          color: #555555;
        }
      }
    }
  }
  .dashboard-body {
    margin-top: 20px;
    .graph-view {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .graph-type {
        display: flex;
        align-items: center;
        gap: 30px;
        .select-graph {
          display: flex;
          align-items: center;
          gap: 15px;
          p {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
      .button-group {
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        position: relative;
        z-index: 1;
        .button {
          text-transform: capitalize;
          color: #555555;
          font-weight: 400;
          border: 0px;
          border-radius: 8px;
          padding: 8px 30px;
        }
        .active {
          background: $theme-purple;
          color: $theme-white;
          border-right: 1px solid rgba(0, 0, 0, 0.2);
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .button:first-child {
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        .button:nth-child(2) {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }
    }
    .button-group-postion {
      display: flex;
      align-items: center;
      justify-content: right;
    }

    .graph {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      .graph-pie-chart {
        height: 600px;
        width: 99%;
        margin-left: 10px;
        margin-bottom: 30px;
        .pie-chart {
          margin-top: 20px;
          .pie-chart-graph {
            height: 400px;
            display: flex;
            align-items: flex-start;
            gap: 10px;
            .pie1 {
              min-width: 50%;
              height: 440px;
              padding-top: 30px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              text-align: center;

              border: 1px solid rgba(0, 0, 0, 0.2);
              border-radius: 10px;
              .pie {
                font-size: 24px;
                font-weight: 600;

                background: linear-gradient(
                  78.58deg,
                  #b756a9 -24.19%,
                  #cc7a64 142.11%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
              }
            }
            .pie2 {
              min-width: 50%;
              height: 440px;
              padding-top: 30px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              border: 1px solid rgba(0, 0, 0, 0.2);
              border-radius: 10px;

              div {
                float: right;
                display: block;
                margin: auto;
              }
              .pie {
                font-size: 24px;
                font-weight: 600;
                background: linear-gradient(
                  78.58deg,
                  #b756a9 -24.19%,
                  #cc7a64 142.11%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
              }
            }
          }
        }
      }
    }

    .candidates-v2 {
      margin-top: -97px;
    }
  }
  .default-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .dashboard {
    .dashboard-body {
      .graph-view {
        .graph-type {
          gap: 10px;
          .select-graph {
            gap: 3px;
            .MuiFormControl-root {
              width: 100px !important;
            }
          }
        }
      }
      .candidates-v2 {
        .joblist-dashbard {
          margin-top: 50px;
        }
      }
    }
  }
}
