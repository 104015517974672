.access-manager {
  padding: 50px 5%;

  .body {
    margin: 30px 0;
    table {
      width: 100%;
      border-spacing: 0 1rem;

      thead {
        padding: 20px 0;
        th {
          font-weight: 500;
        }
      }
      tbody {
        .row {
          line-height: 60px;
          background: $theme-off-white;

          td {
            text-align: center;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }

    .add-access {
      text-align: right;
      button {
        text-transform: capitalize;
        font-weight: 500;
      }
    }
  }
}
