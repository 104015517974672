.portal {
  display: flex;
  // column-gap: 150px;
  overflow: hidden;

  .content-container {
    width: 100%;
    overflow-y: scroll;
    height: 100vh;
  }
}
