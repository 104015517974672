.sidebar {
  background: #fff;

  height: 100vh;
  padding: 40px 0 40px 30px;
  margin-right: 30px;

  .open {
    width: 200px;
  }

  .collapsed {
    width: 60px;
  }

  .logo {
    text-align: center;
    margin: 10px auto;

    img {
      max-width: 140px;
    }
  }

  .menu {
    ul {
      list-style: none;

      li {
        margin: 10px auto;
        font-weight: 500;
        font-size: 16px;
        padding: 10px 15px;
        border-radius: 25px 0 0 25px;
        color: grey;

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: grey;
          column-gap: 10px;
        }
      }

      .active {
        background: #7f00bb;
        a {
          color: #fff;
        }
      }
    }
  }
  .collapse-btn {
    text-align: right;
  }
}
