.joblist-dashbard {
  padding-top: 35px;
  padding-left: 0px;
  font-family: "Poppins", sans-serif;
  position: relative;
  .job-button {
    position: absolute;
    right: 175px;
    z-index: 1;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    .expand {
      margin-top: 13px;
      border: none;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-right: 16px;
      border-radius: 6px;
      padding: 11.4px 25px;
      height: 42px;
      font-size: 14px;
      color: #121212;
      font-weight: 400;
      background: transparent;
      cursor: pointer;
    }
    .type-of-view {
      display: flex;
      align-items: center;
      gap: 10px;
      p {
        font-size: 16px;
        font-weight: 400;
        color: #121212;
        margin-top: 7px;
      }
      .select {
        margin-top: 13px;
        height: 40px;
      }
    }
  }
  .event-button {
    position: absolute;
    right: 175px;
    z-index: 1;
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    .expand {
      margin-top: 5px;
      border: none;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
      margin-right: 16px;
      border-radius: 6px;
      padding: 11.4px 25px;
      height: 42px;
      font-size: 14px;
      color: #121212;
      font-weight: 400;
      background: transparent;
      cursor: pointer;
    }
    .type-of-view {
      display: flex;
      align-items: center;
      gap: 10px;
      p {
        font-size: 16px;
        font-weight: 400;
        color: #121212;
        margin-top: 7px;
      }
      .select {
        margin-top: 6px;
        height: 40px;
      }
    }
  }
 
  .search {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    width: 25vw;
    height: 43px;
    padding: 4px 15px;
  }
  .formControal {
    width: 120px;
    min-width: 120px;

    .select {
      height: 43px;
      color: #121212;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .group {
    .formControal {
      width: 120px;

      min-width: 120px;

      .select {
        height: 43px;
        color: #121212;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .MuiPaper-root {
      .MuiList-root {
        .MuiButtonBase-root {
          padding: 2px 0px 3px 5px;
          .MuiListItemIcon-root {
            all: unset;
            width: 40px;
            .MuiCheckbox-root {
              color: #121212;

              .MuiSvgIcon-root {
                width: 15px;
              }
            }
          }
          .MuiListItemText-root {
            font-size: 12px;
            font-weight: 600;
            font-family: Popine;
          }
        }
      }
    }
  }
  .apply-btn {
    padding: 10px 30px;
    font-size: 19px;
    height: 42px;
    background: transparent;
  }

  .hr {
    color: #000000;
  }
  .body {
    margin-top: 17vh !important;

    .MuiBox-root,
    .MuiDataGrid-root {
      div {
        div {
          .MuiDataGrid-toolbarContainer {
            margin-top: -100px;
            padding-left: 0px;

            button {
              border: none;
              outline: none;
              border: 1px solid rgba(0, 0, 0, 0.2);
              margin-right: 16px;
              border-radius: 6px;
              padding: 7px 25px;
              font-size: 14px;
              color: #121212;
              font-weight: 400;
              background: transparent;
            }
            button:nth-child(4) {
              position: absolute;
              right: 0px;
            }
          }
        }
      }
      .MuiDataGrid-main {
        .MuiDataGrid-columnHeaders {
          .MuiDataGrid-columnHeadersInner {
            color: #7f00bb;
          }
        }
      }
    }
    .MuiDataGrid-root {
      div {
        div {
          .MuiDataGrid-toolbarContainer {
            margin-top: -100px;
            border-bottom: 2px solid #7f00bb;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  .default-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    font-size: 20px;
    font-weight: 600;
    min-height: 60vh;
    color: $theme-purple;

    img {
      display: block;
      margin: auto;
    }
  }
}

.MuiDataGrid-panel {
  .MuiPaper-root {
    width: auto;

    padding-right: 15px;
    position: relative;
    padding-top: 10px;
    border-radius: 10px;
    margin-top: -100px;

    .MuiDataGrid-panelHeader {
      padding: 10px 2px;

      display: none;
      .MuiFormControl-root {
        .css-179tkpm-MuiFormLabel-root-MuiInputLabel-root {
          all: unset;
        }
        .MuiInputLabel-root {
          color: #000000;
          all: unset;
          font-size: 18px;
          font-weight: 500;
        }
        .MuiInput-input {
          font-size: 20px;
          font-weight: 500;
          color: #000000;
        }
      }
    }

    .MuiDataGrid-panelContent {
      display: flex;
      .MuiDataGrid-filterForm {
        .MuiFormControl-root {
          width: auto;
          .css-whhjxc-MuiInputBase-root-MuiInput-root {
            outline: none;
            border: 1.2px solid #7f00bb;
            color: #7f00bb;

            border-radius: 4px;
            padding: 5px 7px;
            font-weight: 500;
          }
        }
      }

      .MuiDataGrid-columnsPanel {
        overflow-y: hidden;
        overflow-y: scroll;
        overflow-x: hidden;

        .MuiDataGrid-columnsPanelRow {
          width: 200px;
          padding-left: 10px;
          .MuiFormControlLabel-root {
            all: unset;
            display: flex;
            align-items: center;
            .MuiSwitch-root {
              width: 20px;
              overflow: hidden;
              margin-bottom: -15px;
              .MuiSwitch-switchBase {
                font-size: 12px;
                background-color: #7f00bb;
                all: unset;
                input[type="checkbox"] {
                  visibility: hidden;
                }

                .MuiSwitch-thumb {
                  all: unset;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 15px;
                  width: 15px;
                  background-color: #999999;
                  border-radius: 3px;
                }
                .MuiTouchRipple-root {
                  left: 4px;
                  top: 3px;
                  // bottom: 5px;
                  width: 3px;
                  height: 5px;
                  border: solid white;
                  border-width: 0 3px 3px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }

                input:checked ~ .MuiSwitch-thumb {
                  background-color: #7f00bb;
                }
                .MuiSwitch-thumb:after {
                  content: "";
                  position: absolute;
                  display: none;
                }
                input:checked ~ .MuiSwitch-thumb:after {
                  display: block;
                }
                .MuiSwitch-thumb:after {
                  left: 4px;
                  bottom: 5px;
                  width: 3px;
                  height: 5px;
                  border: solid white;
                  border-width: 0 3px 3px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }
              }
              .MuiSwitch-track {
                background-color: #000000;
                height: 8px;
                width: 15px;
              }
            }
            .MuiTypography-root {
              padding: 2px 10px;

              font-weight: 500;
              font-size: 14px;
              width: 200px;
            }
          }
        }
      }
    }
    .MuiDataGrid-panelContent {
      .MuiFormControl-root {
        padding: 2px 15px;

        .MuiInputLabel-root {
          all: unset;
          color: #7f00bb;
          font-weight: 600;
          font-size: 16px;
        }
        .MuiInput-root {
          width: 130px;
          margin-right: 10px;

          .css-1q5w4r7-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
            border: none;
            all: unset;
          }
          .MuiNativeSelect-select {
            width: 200px;
            outline: none;
            border: 1.2px solid #7f00bb;
            color: #7f00bb;

            border-radius: 4px;
            padding: 10px 7px;
            font-weight: 500;
            option {
              color: black;
              font-size: 14px;
              font-weight: 400;
              margin: 10px;
              padding: 10px;
            }
          }
          .css-1q5w4r7-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
            border-bottom: none !important;
          }
        }
      }
    }

    .MuiDataGrid-panelFooter {
      display: block;
      .MuiButton-root {
        all: unset;
        span {
          margin: 0;
        }
      }
      .MuiButton-root {
        font-size: 0;
        .MuiButton-startIcon {
          .MuiSvgIcon-root {
            position: absolute;
            right: 15px;
            margin-top: -10vh;

            background-color: #7f00bb;

            text-align: center;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1100px) and (max-width: 2824px) {
  .joblist-dashbard {
    .event-button {
      margin-right: 2vw;
    }
    .job-button {
      margin-top: 0px;
      right: 230px;
    }
    .body {
      margin-top: 130px !important;
    }
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .joblist-dashbard {
    margin-right: 10px;
    .event-button {
      margin-right: 0vw;
    }
    .job-button {
      margin-top: 1px;
      right: 145px;
    }
    .body {
      margin-top: 130px !important;
      .MuiBox-root {
        width: 100% !important;
      }
    }
  }
}
